import { useEffect } from 'react';

import { useNavigate } from '@hooks/useNavigate';

const VolunteerPage = () => {
  const navigate = useNavigate();
  /**
   * Just for backward compability with old site
   *
   * TODO: make normal redirect using other ways.
   */

  useEffect(() => {
    navigate(`/cabinet/volunteer`);
  }, []);

  return null;
};

export default VolunteerPage;
